export const api = {
    detail: "api/detail",
    weChatPay: "api/wxPayWap",
    wxPayJS: "api/wxPayJS",
    aliPayWap: "api/zfbPay",
    payStatus: "api/payStatus",
    aliPayNotify2: "api/aliPayNotify2",
    homePage: "api/homePage",
    getIpCity: "api/getIpCity",
    createClick: "api/createClick",
    submitUserInfo: "api/submitUserInfo"
}

export default api;